body {
  font-family: $primary-font, 'Malgun Gothic', 'Dotum', 'Golum';
}

.cs-page__content {
  .select-box__options.open {
    z-index: 0;
  }
}

.ini_pay_wrapper {
  padding-top: 30px;
}

.site-header {
  .site-header {
    &__main {
      .site-header {
        &__nav {
          .site-header {
            &__promo {
              .user-loyalty {
                .user-loyalty {
                  &__program {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cart-block__items {
  .cart-item__info {
    .cart-item__points {
      span {
        display: none;
      }
    }
  }
}

.site-header__menu {
  .site-header__menu-list--desktop {
    .menu__item--lvl-2 {
      .menu__item-trigger {
        font-weight: bold;
      }
    }
    .menu__item--lvl-3 {
      a {
        line-height: 2em;
      }
    }
    .menu--lvl-1 {
      @include breakpoint($landscape-down) {
        font-size: 11px;
      }
    }
  }
  .menu__subnav {
    li.menu__item.menu__item--lvl-2 {
      &.menu__item--category {
        a.menu__item-trigger {
          font-size: 16px;
        }
      }
    }
  }
}

.content-block-large__text {
  p {
    text-align: $ldirection;
  }
  &.content-block-large__text--1 {
    &.font-normal.header--medium.font-color--dark {
      p {
        font-size: 34px;
      }
    }
  }
  &.content-block-large__text--3 {
    &.font-normal.text--large.font-color--dark {
      p {
        font-size: 15px;
      }
    }
  }
  &.content-block-large__text--2 {
    &.font-normal.text--large.font-color--light {
      p {
        font-size: 20px;
      }
    }
  }
}

body.toolbar {
  .checkout {
    margin-top: 175px;
  }
}

.signin-overlay {
  .signin-block__checkboxes {
    .signin-block__checkbox {
      input[type='checkbox'] {
        margin-top: 6px;
      }
    }
  }
  .new-account {
    .form_input {
      .form_mobile_input {
        input {
          width: 110px;
        }
      }
    }
  }
  .radio_group {
    input[type='radio'] {
      margin-top: 5px;
    }
  }
  .signin-block__field-wrapper {
    .form_input {
      input[type='checkbox'] {
        margin-top: 6px;
      }
    }
  }
  #form--registration_short--field--MOBILE_BYPASS {
    margin-top: 6px;
  }
  .form_label {
    .label {
      margin-left: 8px;
    }
  }
}

.product__social-container.product-social {
  i {
    font-size: 24px;
  }
}

.site-content {
  p {
    line-height: 1.375;
  }
  .content-block-large__text.text--small {
    p span {
      font-size: 12px;
      padding-bottom: 10px;
    }
  }
  .social-gallery.social-gallery--v1 {
    padding-bottom: 0;
  }
  .content-block-large__text {
    &.content-block-large__text--1 {
      &.header--large {
        p {
          font-size: 28px;
        }
      }
    }
  }
  .tout__content-inner {
    .tout__header.tout__header--large {
      font-size: 28px;
    }
  }
  .tabbed-tout__tabs {
    .tabbed-tout__title {
      max-width: 500px;
      line-height: 1.375em;
      font-size: 21px;
      b {
        font-size: 28px;
        line-height: 1.275em;
      }
    }
  }
  .product-grid__content {
    margin-bottom: 30px;
  }
  .spp__container {
    .block-template-content-block-large-split-width-v1 {
      .content-block-split__inner {
        width: 35%;
      }
    }
    .content-block-split--video.content-block-split--image-right {
      .content-block-split__image {
        width: 80%;
      }
    }
    .content-block-split--video.content-block-split--image-left {
      .content-block-split__image {
        width: 84%;
      }
    }
    .content-block-split__image {
      width: auto;
    }
    .content-block-split__inner {
      width: 93%;
    }
    .product-full__quote.text--quote {
      display: none;
    }
    h3[data-info-section='RESPONSIBLE_PACKAGING'] {
      display: none;
    }
    .spp_customer_reviews {
      #pr-reviewdisplay {
        .pr-helpful-yes {
          .pr-helpful-count::before {
            content: '예 ·';
          }
        }
        .pr-helpful-no {
          .pr-helpful-count::before {
            content: '아니 .';
          }
        }
      }
    }
    .content-block-split__text--2.font-normal.text--small {
      margin-left: 40px;
    }
  }
  .content-block-split__image {
    width: auto;
  }
  .content-block-split__inner {
    width: auto;
  }
}

.section-product {
  .product-full__quote.text--quote {
    font-size: 20px;
  }
  h1.product-info-area__info-product-name {
    font-size: 24px;
    font-weight: normal;
  }
  .content-block-split__text {
    &.content-block-split__text--1.font-normal {
      &.header--small.font-color--dark {
        p {
          font-size: 20px;
        }
      }
    }
    .content-block-split__text--2.font-normal {
      p {
        margin-left: 40px;
      }
    }
    &.font-normal.header--smallest {
      &.font-color--dark {
        p {
          font-size: 20px;
        }
      }
    }
  }
  .ritual-step-formatter__text {
    &.header--medium.font-color--dark {
      font-size: 30px;
      line-height: 1.375;
    }
  }
  .content-block-split--video {
    margin-top: 60px;
    &.content-block-split__text {
      &.font-normal.text--small.font-color--dark {
        p {
          margin-right: 16px;
          font-size: 20px;
        }
      }
    }
  }
  .product-full__name {
    font-size: 24px;
    line-height: 1.2;
  }
  .basic-noderef {
    margin: 20px 0 20px 108px !important; // To overrule AV base style that misaligned this section
  }
}

.search-wrapper {
  .ritual-step-formatter,
  .offer-block-formatter {
    display: none;
  }
}

.cs-page__content-item {
  #contact_us {
    .fieldset.my_message {
      margin-top: 10px;
      textarea {
        font-size: 16px;
        margin-bottom: 22px;
      }
    }
  }
}

.mpp-container {
  .product-grid__item {
    height: auto;
  }
  .product-brief__footer {
    min-height: 285px;
  }
  .mpp-sort__container {
    .selectBox {
      .select-box__options {
        div[value='name_a_z'] {
          display: none;
        }
      }
    }
  }
}

#pr-write {
  .p-w-r :focus,
  .p-w-r a:focus {
    outline: 0;
    box-shadow: 0px 0px 5px $white;
  }
}

.content-block-large__text-wrapper {
  .content-block-large__text--1 {
    p {
      font-size: 26px;
    }
  }
  .content-block-large__text.content-block-large__text--1 {
    &.font-normal.text--large {
      p {
        font-size: 17px;
      }
    }
  }
}

header.la-landing-nav-formatter__header {
  p {
    font-size: 26px;
  }
}

.la-article-formatter__content {
  .content-block-large__text--2 {
    font-size: 28px;
  }
}

.la-article-formatter__content-item {
  .tout__content-inner {
    .tout__header.tout__header--large {
      font-size: 54px;
    }
  }
}

.homepage-wrapper {
  .content-block-large__text.content-block-large__text--1.font-normal.header--medium {
    p {
      font-size: 34px;
    }
  }
  .content-block-large__text.content-block-large__text--3.font-normal {
    p {
      font-size: 15px;
    }
  }
  .content-block-large.clickable-processed.block--linked {
    padding-bottom: 10px;
  }
  .content-block-large__text.content-block-large__text--2.font-normal.text--large {
    p {
      font-size: 20px;
    }
  }
  .content-block-large__text.content-block-large__text--1.font-normal.header--large {
    P {
      font-size: 50px;
    }
  }
  .content-block-large__text.content-block-large__text--1.font-light.header--small {
    line-height: 1.375;
  }
  .basic-tout__text-copy.font-normal.header--medium.font-color--dark {
    p {
      font-size: 30px;
    }
  }
}

.store-locator__tooltip-product-types {
  .pc--hc {
    background: url(/media/icons/hair_color_icon.png);
    width: 18px;
    height: 18px;
    text-indent: -9999px;
  }
  .pc {
    border: none;
  }
  .pc--sr {
    background: url(/media/icons/sensory_rituals_icon.png);
    width: 18px;
    height: 18px;
    text-indent: -9999px;
  }
  .pc--ss {
    background: url(/media/icons/special_services_icon.png);
    width: 18px;
    height: 18px;
    text-indent: -9999px;
  }
  .pc--em {
    background: url(/media/icons/earth_mother_icon.png);
    width: 18px;
    height: 18px;
    text-indent: -9999px;
  }
  .pc--pp {
    background: url(/media/icons/pure_privilege_icon.png);
    width: 18px;
    height: 18px;
    text-indent: -9999px;
  }
}

.section-products {
  .content-block-split__text--2.font-normal.text--small {
    margin-left: 40px;
  }
}

.product-full__quote.product-quickshop__quote {
  display: none;
}

.cs-contact-form__errors {
  color: $color-red;
}

.domain-kr .welcome-15 {
  height: 302px;
  .site-email-signup__success--welcome-15 {
    form {
      .email_input {
        input[type='email'] {
          display: none;
        }
        input[type='submit'].form-submit {
          display: none;
        }
      }
    }
  }
  .site-email-signup__success-offer--line1 {
    .exclusive-offer-link {
      position: absolute;
      top: 190px;
      max-width: 250px;
      border: 1px solid #392720;
      color: #392720;
      font-size: 15px;
      line-height: 33px;
      height: 33px;
      text-align: center;
      padding: 0 30px;
      text-decoration: none;
    }
  }
}

.domain-kr .welcome15-overlay {
  #cboxClose {
    top: 19px;
    right: 18px;
    &::before {
      width: 10px;
      height: 10px;
    }
  }
}
// customer service landing
.section-customer-service {
  .cs-section {
    justify-content: normal;
  }
  .cs-section__col {
    width: 30.3333%;
    margin-right: 3%;
  }
}

.section-mpp-franchise-tulasara {
  .site-content {
    .basic-noderef {
      .content-block-split__inner {
        .header--medium {
          p {
            font-size: 28px;
          }
        }
      }
    }
  }
}

.section-mpp-franchise-chakra {
  .site-content {
    .basic-noderef {
      .content-block-large__inner-vpos--middle {
        .content-block-large__content {
          left: 40%;
        }
      }
    }
  }
}

.section-body {
  .site-content {
    .basic-noderef--padding {
      .tabbed-tout {
        .tabbed-tout__content-wrapper {
          top: -60px;
        }
      }
    }
  }
}

.section-hair-care {
  .site-content {
    .basic-noderef--padding {
      .basic-grid-wrapper--32 {
        padding-bottom: 50px;
      }
    }
    .basic-noderef {
      .content-block-split--image-left,
      .content-block-split--image-right {
        .content-block-split__content {
          text-align: center;
          p {
            padding-right: 20px;
          }
        }
      }
      .basic-grid-wrapper {
        .basic-grid__item {
          &:first-child {
            .basic-grid__item-inner--32 {
              padding-top: 30px;
            }
          }
        }
      }
    }
  }
}

.section-skin-care {
  .site-content {
    .field-content {
      .basic-noderef {
        .content-block-large__inner-vpos--middle {
          .content-block-large__content {
            left: 70%;
          }
        }
      }
      .mpp-container--custom {
        .product-grid__content {
          .product-grid__item {
            .tout__text {
              .tout__text-inner {
                .tout__header--small {
                  font-size: 22px;
                  line-height: 1.275;
                }
              }
            }
          }
        }
      }
    }
  }
}

#profile_preferences {
  .profile-page__content {
    form {
      .promotional-info {
        .optional-info {
          margin-top: 20px;
        }
      }
    }
  }
}

.spp__container {
  .product-full__details {
    .product-full__description {
      height: auto !important;
      .product-full__description-more {
        display: none;
      }
    }
  }
}

.product-grid {
  .product-quickshop-wrapper {
    .product-full__description {
      height: auto !important;
      .product-full__description-more {
        display: none;
      }
    }
  }
}

.elc-user-state-anonymous {
  .site-header {
    .signin-overlay {
      .signin-block__form--register {
        .sigin-block__mobile-fields {
          .signin-block__field-wrapper {
            &:nth-child(1) {
              .form_input {
                width: auto;
                .form_select {
                  width: 25%;
                  .select-box {
                    margin-top: 11px;
                  }
                }
              }
            }
          }
        }
      }
      &.signin-block--signin {
        form {
          .js-forgot-password {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .site-content {
    .la-landing {
      .la-landing__content-main {
        .la-landing__content-item {
          .content-block-split__inner,
          .content-block-large__inner {
            .content-block-split__text--2,
            .content-block-large__text--2 {
              p {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
    .la-article-formatter__content {
      .la-article-formatter__content-item {
        .content-block-split__content {
          h2.header--medium {
            p {
              font-size: 34px;
            }
          }
        }
        &:nth-child(1) {
          .tout__content {
            .tout__content-inner {
              h2.tout__header--large {
                font-size: 44px;
              }
            }
          }
        }
        &:nth-last-child(2) {
          .content-block-large__text--1 {
            p {
              font-size: 22px;
            }
          }
        }
        &:nth-child(2) {
          .la-article-text__content {
            h2 {
              span {
                font-size: 28px !important;
              }
              &:nth-child(2) {
                font-size: 28px;
              }
            }
            h3 {
              line-height: 1.5;
            }
          }
        }
        &:nth-child(3),
        &:nth-child(5) {
          .la-article-text__content {
            h3 {
              font-size: 20px;
              strong {
                font-size: 20px !important;
              }
            }
          }
        }
        &:nth-child(5),
        &:nth-child(6) {
          .content-block-split__text--1 {
            p {
              font-size: 28px;
            }
          }
        }
        &:nth-child(6) {
          .la-article-text__content {
            h3 {
              font-size: 20px;
              strong {
                font-size: 20px !important;
              }
            }
          }
        }
        &:nth-child(7) {
          .la-article-text__content {
            h2 {
              &:nth-child(3) {
                font-size: 28px;
              }
            }
          }
        }
        &:last-child {
          p {
            &:nth-last-child(1),
            &:nth-last-child(2) {
              font-size: 14px;
            }
          }
          .la-article-text__content {
            h2 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

#signin {
  .sign-in-page {
    form {
      .new-account__fieldset {
        .mobile_pin_field {
          .form_label {
            display: none;
          }
        }
      }
    }
  }
}
// Power reviews
.spp_customer_reviews {
  padding-top: 50px;
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-review {
          .pr-rd-footer {
            .pr-rd-helpful-action {
              .pr-helpful {
                &-yes {
                  .pr-helpful-count {
                    &:before {
                      content: '네 ·';
                    }
                  }
                }
                &-no {
                  .pr-helpful-count {
                    &:before {
                      content: '아니오 ·';
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#viewcart {
  .checkout__sidebar {
    .checkout-panel--offer-code {
      .offer-code-content {
        .js-edit-offer {
          text-decoration: underline;
          margin-left: 5px;
        }
      }
    }
  }
}

#order_return {
  .sidebar-page__content {
    #return-info-wrapper {
      .address-form-container {
        .form-item-postal {
          margin-bottom: 10px;
          div {
            width: auto;
            float: left;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.spp-content {
  .product-full {
    .p-w-r {
      .pr-review-snippet-container {
        float: none;
      }
    }
    .product-shade-picker {
      width: 100%;
    }
  }
}

#zero_dollar_overlay {
  h3 {
    margin-top: 16px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .zero-dollar {
    &-buttons {
      display: flex;
      padding-top: 40px;
      .button {
        margin: 0px auto;
        color: $color-white;
        background-color: $color-black;
      }
    }
    &-success,
    &-error {
      text-align: center;
      font-size: 28px;
      padding-top: 38px;
    }
  }
}

#colorbox {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  #cboxContent {
    #cboxLoadedContent {
      width: 461px;
      height: 302px;
    }
  }
}

.basic-noderef:not(:first-child) {
  .content-block-large {
    &__image.content-block-large {
      &__image {
        &__auto-height {
          margin-top: 2%;
        }
      }
    }
  }
}

.sticky-footer-block {
  &-formatter {
    .sticky-footer-block-links {
      &.no-bar {
        border-#{$ldirection}: 2px solid $white;
      }
    }
  }
}

.adv_cal_cap {
  .adv-cal-cap-container {
    .adv-cal-cap-popup {
      .popup-content-wrapper {
        .offer-details {
          &__img,
          &__content,
          &__controls {
            float: unset;
            text-align: center;
          }
        }
      }
    }
  }
}

.write_a_review {
  &__container {
    #pr-write {
      .pr-subscript {
        display: none;
      }
    }
  }
}
