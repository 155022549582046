// Seol Sans W05 Thin
@font-face {
  font-family: 'SeolSans';
  src: url('#{$netstorage-fonts-path}cjk/SeolSans_KR/5275ac44-ecef-47f2-904f-2a421e7a7788.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'SeolSans';
  src: url('#{$netstorage-fonts-path}cjk/SeolSans_KR/5275ac44-ecef-47f2-904f-2a421e7a7788.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

// Seol Sans W05 Medium
@font-face {
  font-family: 'SeolSans';
  src: url('#{$netstorage-fonts-path}cjk/SeolSans_KR/a6f40d8b-1c8d-44ce-8a78-cd40f82c039c.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'SeolSans';
  src: url('#{$netstorage-fonts-path}cjk/SeolSans_KR/a6f40d8b-1c8d-44ce-8a78-cd40f82c039c.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

html,
body {
  font-family: $kr-primary-font;
}
