.viewcart {
  .checkout__content {
    .checkout-panel--viewcart {
      .cart-items__item.product {
        padding-left: 0;
      }
      .viewcart_page_subtotal {
        float: left;
        width: 100%;
        border-bottom: 1px solid #cdcbc9;
        .subtotal.label {
          float: left;
          width: 85%;
          border-top: 1px solid #cdcbc9;
          font-weight: bold;
          padding: 1em 0;
        }
        .subtotal.value {
          float: right;
          border-top: 1px solid #cdcbc9;
          font-weight: bold;
          margin-bottom: 0;
          padding: 1em 0;
          width: 15%;
        }
      }
      .checkout-panel {
        clear: both;
      }
    }
    .checkout-panel--promo-bca {
      display: none !important;
    }
    .on-the-go-item {
      min-height: 415px;
      .details {
        min-height: 150px;
        .add-to-cart {
          bottom: 0px;
          position: absolute;
        }
      }
    }
    .signin-to-see-cart a {
      color: $color-dark-red;
      font-weight: bold;
    }
  }
  .on-the-go-content {
    &__products-list {
      li.product {
        width: 33.3%;
        float: left;
      }
    }
  }
  .checkout-panel {
    &--order-summary {
      margin-bottom: 15px;
      .ship-method__information {
        margin-bottom: 0px;
      }
    }
    &--links {
      .links-content {
        margin-bottom: 25px;
      }
      .checkout-panel__header {
        margin-top: 10px;
      }
    }
    &--offer-code {
      padding-bottom: 10px;
    }
  }
  .cart-items__details {
    width: 85%;
    .cart-items__item--desc {
      margin-left: 10px;
      float: left;
    }
    .price {
      float: left;
    }
    .qty {
      margin-left: 38px;
      float: left;
    }
    .total {
      margin-left: 15px;
      float: left;
    }
  }
}

.checkout-panel--order-summary {
  .checkout-panel__content {
    .label {
      width: 70%;
    }
    .value {
      width: 30%;
    }
  }
}

.checkout {
  &.checkout-single-page {
    ol.checkout-progress,
    ol.checkout__navigation {
      list-style: decimal;
      width: 74%;
      li {
        margin: 0 1%;
        padding: 5px 0;
        float: left;
        list-style-position: inside;
        line-height: 20px;
        width: 23%;
      }
    }
  }
  .cart-items {
    .cart-items {
      &__item--desc {
        .vsku_product_contains,
        .cart-item__subitems {
          margin-bottom: 20px;
        }
      }
    }
  }
  .checkout__navigation {
    width: 100%;
    float: left;
    margin: 15px 0;
  }
  .checkout__content {
    border-top: none;
    #form--errors--checkout_review {
      margin-top: 15px;
      li[id='offer_bogo..'] {
        display: none;
      }
    }
    .submit {
      text-align: right;
    }
    .shipping-address-info {
      .checkout-panel__header {
        .checkout-panel__heading {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid $light-gray;
          display: block;
        }
      }
      .checkout-panel__content {
        width: 45%;
        display: inline-block;
        vertical-align: top;
        .address-container {
          font-style: normal;
        }
        .address_controls {
          #choose-address {
            margin-top: 16px;
            select.address-select {
              width: 100%;
            }
          }
          #delivery-instructions {
            input {
              width: 100%;
            }
          }
        }
        #delivery-instructions {
          padding-top: 10px;
        }
      }
      .delivery-info-content {
        margin-top: 50px;
        margin-bottom: 30px;
        #delivery-instructions {
          input {
            width: 96%;
          }
        }
      }
    }
    .giftwrap-info {
      .gift-options-wrapper {
        .form_element {
          display: inline-block;
          width: 48%;
          input[type='text'] {
            width: 100%;
          }
        }
        .shipping__giftmsg-to-container {
          margin-right: 22px;
        }
        .form-item {
          textarea {
            width: 100%;
          }
        }
        .giftwrap_header {
          font-weight: bold;
          margin-bottom: 20px;
          margin-top: 10px;
        }
        .giftwrap_container {
          display: block;
          width: 100%;
          p {
            font-weight: bold;
          }
        }
      }
    }
  }
  #review-instructions {
    margin-top: 15px;
    margin-bottom: 30px;
  }
  #payment-method,
  #shipping-panel {
    .checkout__panel-title {
      padding-bottom: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      border-bottom: 1px solid $light-gray;
    }
  }
  .shipping-panel {
    .trans_detail_item {
      float: left;
      margin-bottom: 25px;
      margin-right: 25px;
      position: relative;
      width: 190px;
      word-wrap: break-word;
      h4 {
        border-bottom: 1px solid #ccc;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 5px;
        padding-right: 16px;
        text-transform: none;
      }
      a.change_link {
        position: absolute;
        right: 0;
        top: 2px;
      }
      address {
        font-style: normal;
      }
      #gift-info {
        display: none;
      }
      .gift-order {
        #gift-info {
          display: block;
        }
      }
    }
  }
  #viewcart-panel {
    .total.review_subtotal {
      width: 100%;
      float: left;
      border-bottom: 1px solid #cdcbc9;
      .total_label {
        width: 90%;
        float: left;
        text-align: right;
        padding: 1em 0;
        border-top: 1px solid #cdcbc9;
      }
      .total.cart_subtotal {
        float: right;
        width: 10%;
        padding: 1em 0;
        border-top: 1px solid #cdcbc9;
      }
    }
  }
  #checkout-sidebar {
    .checkout-panel {
      #offer_code {
        .offer-code-content__form-text {
          float: left;
          margin-bottom: 20px;
        }
      }
    }
  }
}

#shipping {
  .checkout-single-page {
    ol.checkout-progress,
    ol.checkout__navigation {
      .shipping {
        font-weight: bold;
      }
    }
    #delivery-options {
      .select-box {
        width: 345px;
        .select-box__label {
          text-overflow: ellipsis;
        }
      }
    }
  }
  #colorbox {
    #cboxContent {
      .address-update-confirm {
        h2 {
          text-align: center;
        }
        .change-address-content {
          padding: 0 10%;
          text-align: center;
        }
        .address-confirm-buttons {
          text-align: center;
          margin-top: 15px;
          .address-confirm,
          .address-cancel {
            display: inline-block;
          }
          .address-confirm {
            margin-right: 10px;
          }
        }
      }
      #cboxClose {
        &:before {
          width: 20px;
          height: 20px;
          line-height: get-line-height(20px, 30px);
        }
      }
    }
  }
}

#review {
  .checkout-single-page {
    ol.checkout-progress,
    ol.checkout__navigation {
      .review {
        font-weight: bold;
      }
    }
    .cart-items {
      .cart-items__item--desc {
        min-height: 150px;
      }
    }
  }
}

#billing {
  .checkout-single-page {
    ol.checkout-progress,
    ol.checkout__navigation {
      .billing {
        font-weight: bold;
      }
    }
  }
}

#confirm {
  .checkout-single-page {
    ol.checkout-progress,
    ol.checkout__navigation {
      .confirm {
        font-weight: bold;
      }
    }
  }
}

.address-book-page__overlay {
  .address-form {
    .form-item {
      .phone_part_area_code {
        .selectBox {
          min-width: 184px;
          height: 43px;
          margin-top: 2px;
          border: 1px solid #392720;
        }
      }
    }
  }
}

#shipping-detail-popup {
  .checkout-panel__header {
    text-align: center;
    font-size: 26px;
  }
  table {
    td {
      padding-top: 10px;
      padding-bottom: 15px;
      h3 {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
  }
}

#confirm {
  #confirmation-page {
    margin-top: 83px;
    .confirmation-panel {
      .confirmation-panel__actions {
        margin-left: 0;
        margin-top: 10px;
      }
    }
    h3 {
      margin-bottom: 22px;
    }
    .checkout__content {
      #content {
        margin-top: 17px;
      }
    }
    .return-link-container {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
  .checkout {
    a.btn {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 9px 19px 8px 19px;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      min-width: 116px;
      max-width: 100%;
      font-size: 15px;
      line-height: 16px;
      background: #fbf8f6;
      color: #392720;
      border: 1px solid #392720;
      background: #392720;
      color: #fbf8f6;
      -webkit-appearance: none;
      border-radius: 0;
      position: relative;
      top: 1px;
    }
  }
}

#shipping-address-info-panel {
  #address_form_container {
    .required-message {
      min-height: 35px;
    }
    .address3_container {
      margin-bottom: 20px;
    }
    .form_element {
      width: 50%;
      float: left;
      .address_lookup_submit {
        input {
          width: 50%;
        }
      }
      input {
        width: 100%;
      }
    }
    .postal_code_container {
      position: relative;
      clear: both;
      span.fieldset-note {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        padding-left: 20px;
      }
    }
    .address1_container,
    .address2_container,
    .address3_container {
      width: 100%;
    }
    .phone_1_container,
    .phone_2_container {
      .phone_part_area_code {
        float: left;
      }
      .select-box,
      select {
        width: 37%;
        .select-box__label {
          min-width: 94px;
          height: 35px;
        }
      }
      input.field {
        width: 100%;
      }
      .phone_part.phone1_1,
      .phone_part.phone1_2,
      .phone_part.phone2_1,
      .phone_part.phone2_2 {
        width: 31%;
        float: left;
        margin: 0 5px;
      }
    }
  }
}

.error {
  color: #ff0000;
}

#samples {
  .samples-content {
    &__form {
      .product-list-container {
        .product {
          padding: 0.5%;
        }
      }
    }
  }
}

#order_detail {
  .account-page {
    .account-breadcrumb-wrapper {
      .account-breadcrumb {
        font-size: 21px;
      }
    }
  }
}
