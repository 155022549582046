.new-account {
  &.signin-block__form--register {
    display: none;
  }
  .form-item {
    padding-top: 10px;
  }
  .optional-info {
    &__header {
      font-weight: 700;
      text-transform: uppercase;
      margin: 10px 0;
    }
    &__item {
      .form-item {
        padding-top: 10px;
        display: inline-block;
        margin-#{$rdirection}: 15px;
      }
    }
    &__item--header {
      margin: 5px 0;
    }
  }
  .form_input {
    width: 520px;
    padding-top: 10px;
    span.form_select,
    span.form_mobile_input,
    span.form_mobile_pin_input {
      display: inline-block;
      clear: both;
    }
    .form_mobile_input input {
      width: 96px;
    }
    .form_mobile_pin_input {
      input {
        width: 200px;
      }
    }
    .form_select {
      width: 26%;
      .select-box {
        margin-top: 5px;
        &__label {
          height: 35px;
          min-width: 130px;
          width: 11%;
        }
      }
    }
    .form_mobile_pin_input {
      padding-right: 10px;
    }
  }
  .terms_agree {
    .radio_group {
      display: inline-block;
      span.radio {
        margin-right: 10px;
      }
    }
    .group_label {
      margin-bottom: 10px;
    }
    .legal_statement {
      overflow-x: hidden;
      height: 100px;
      border: 1px solid #cccccc;
      padding: 0 11px;
      line-height: 1.5em;
    }
    .radio_group {
      margin: 8px 0 10px 0;
      span.radio {
        float: left;
      }
    }
  }
  .form-item.pc_email_promo,
  .form-item.sms_promo,
  .form-item.postal_promo {
    span.radio {
      display: inline-block;
      padding-right: 10px;
    }
  }
  .promotion_notes {
    padding-top: 20px;
  }
  &__fieldset {
    margin-bottom: 10px;
  }
}

.sign-in-page {
  .section-head__link {
    border-bottom: none;
  }
}

#profile_preferences {
  .mobile_number_field {
    width: 100%;
    .form_label,
    .form_input {
      width: 100%;
    }
    .select-box {
      min-width: 50px;
    }
    .select-box__options {
      top: 34px;
      z-index: 0;
      min-width: 50px;
    }
    .form_input {
      span {
        float: left;
        width: 20%;
        input {
          width: 90%;
        }
        .select-box__label {
          width: 10%;
          min-width: 72px;
          height: 35px;
        }
        &.form_select {
          width: 13%;
        }
      }
    }
  }
  .promotional-info {
    .pc_email_promo_container {
      padding-top: 10px;
      span.radio {
        display: inline-block;
        padding-right: 10px;
        padding-top: 2px;
        width: 12%;
        input {
          width: 27%;
        }
      }
      .field_label {
        width: 98%;
        display: inline-block;
      }
    }
  }
  .profile-info__item.mobile_pin_field {
    .form_label {
      padding-top: 10px;
    }
    width: 100%;
    .form_mobile_pin_input {
      width: 48%;
      float: left;
      margin-right: 10px;
    }
  }
  #providing-email {
    padding-top: 10px;
  }
  .select-box__options {
    z-index: 12;
  }
  .withdrawal-link {
    a {
      font-size: 12px;
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #392720;
      }
    }
  }
  .profile-info__item.first-name {
    width: 80%;
  }
  .guide_txt {
    margin-bottom: 10px;
  }
  .profile-info__item.pwd {
    .lable {
      margin-bottom: 10px;
    }
  }
}

.account-withdrawal {
  .margin_top_bottom {
    textarea {
      width: 190px;
      margin: 10px 0;
    }
  }
  .registration-page__membership-withdrawl {
    textarea {
      width: 100%;
    }
  }
}

#address_form_container,
.address-overlay,
#shipping_billing {
  .address-form,
  .address {
    .phone1-fields {
      .form-item {
        width: 33.2%;
        float: left;
      }
      label {
        display: inline;
      }
      .select-box__label {
        min-width: 215px;
        height: 43px;
      }
    }
    .phone_part {
      margin-top: 20px;
    }
    .select-box__options {
      top: 42px;
    }
    .form-item {
      .address_lookup_submit {
        input {
          background: #392720;
        }
      }
    }
  }
  input[type='button']:hover {
    background: #392720;
    color: #ffffff;
  }
  #address-lookup-container {
    .address-search {
      margin-top: 25px;
      padding-top: 0px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 24px;
    }
    h4,
    .address-info {
      padding-bottom: 10px;
    }
    .keyword-hint {
      padding-top: 10px;
    }
    .input_town {
      width: 67%;
      height: 2.1em;
      padding: 6px 10px 8px 10px;
      line-height: 20px;
    }
    .town-submit-btn {
      margin-left: 7px;
      width: 30%;
      height: 33px;
    }
    .address-hint-container {
      .hint-table {
        margin-top: 5px;
        width: 100%;
        th {
          padding: 10px 0 10px 0;
          background: #f2f3f4;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000;
          text-align: center;
        }
        td {
          text-align: center;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #000;
        }
      }
    }
    .address-results-container {
      .rna-list {
        margin-top: 5px;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        th.sno,
        th.full-address,
        th.postal {
          background: #f2f3f4;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
          padding-top: 10px;
          text-align: center;
        }
        td.sno,
        td.postal {
          width: 10%;
          text-align: center;
          padding-bottom: 15px;
          padding-top: 10px;
          border-bottom: 1px solid #ccc;
          font-size: 12px;
        }
        td.full-address {
          width: 60%;
          text-align: center;
          padding-bottom: 15px;
          padding-top: 10px;
          border-bottom: 1px solid #ccc;
          font-size: 13px;
          cursor: pointer;
        }
        .roadAddrPart1,
        .roadAddrPart2 {
          display: none;
        }
      }
      .address1 {
        display: block;
        padding: 5px 0 5px 0;
        cursor: pointer;
      }
      #rna-page-list {
        text-align: center;
        margin-top: 10px;
      }
    }
    .building-name-registered,
    .address-results-container {
      padding: 11px 0;
    }
    .address-input-container {
      .address-details {
        .street {
          margin: 10px 0;
          label {
            font-weight: bold;
          }
        }
        .detail-address {
          .input_address_2 {
            width: 80%;
            margin-left: 18px;
          }
        }
        .building {
          margin-left: 89px;
          margin-top: 10px;
        }
        .zip {
          display: none;
        }
      }
      .address-submit-button {
        input {
          font-size: 12px;
          margin-left: 34%;
          margin-top: 4%;
          width: 40%;
          cursor: pointer;
        }
        input:hover {
          background: #392720;
          color: #fbf8f6;
        }
      }
    }
  }
  &.address-book-page__overlay {
    #cboxClose {
      right: 25px;
    }
    .address-form {
      .addresses {
        .address3 {
          display: block;
        }
      }
    }
  }
  .address-delete__header,
  .address-delete__info {
    text-align: center !important;
  }
  &.address-delete {
    text-align: center;
  }
}

.password-reset-page,
.password-request-page,
.password-sent-page {
  padding-left: 46px;
}

.password-reset-page {
  .password-reset-hint {
    margin-bottom: 20px;
    width: 100%;
  }
}

.account-section {
  &.past-purchases {
    a.account-breadcrumb {
      width: 70%;
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 10px;
      letter-spacing: -2px;
      bottom: 11px;
      text-align: left;
      padding: 0 0 12px;
      float: left;
      padding-left: 15px;
      text-decoration: none;
      border-bottom: 1px solid #cdcbc9;
      position: initial;
      cursor: default;
    }
    a:hover {
      color: #392720;
    }
    .sidebar-page__content {
      float: left;
      padding-left: 22px;
      h2 {
        padding-bottom: 18px;
        font-size: 36px;
        text-align: center;
      }
      th.order-status-table__cell.order-status-table__cell--header {
        width: 14%;
        min-height: 27px;
        height: auto;
        border-bottom: 1px solid #cdcbc9;
        color: #969696;
        letter-spacing: 0.06em;
        padding-bottom: 15px;
      }
      tr.order-status-table__row {
        td {
          width: 14%;
          min-height: 27px;
          height: auto;
          letter-spacing: 0.06em;
          padding-bottom: 15px;
          border-bottom: 1px solid #cdcbc9;
          padding-top: 15px;
          a {
            text-decoration: underline;
          }
        }
        .order-status-table__cell {
          .order_status_text {
            margin-bottom: 0;
          }
        }
      }
    }
    .sku-brief-editorial__image {
      width: 144px;
      height: 200px;
      background-color: #eaebef;
      margin: 0 auto 1em auto;
    }
    .sku-brief-editorial {
      width: 33.33333%;
      padding: 0 1%;
      float: left;
      margin-bottom: 2em;
      text-align: center;
      a {
        min-height: 50px;
        display: block;
      }
      .sku-brief-editorial__shade {
        margin-bottom: 10px;
        display: inline-block;
        text-align: left;
        max-width: 80%;
        min-height: 22px;
        line-height: 22px;
      }
    }
    .sku-brief-checkout__controls {
      .button--more-details {
        width: 100%;
        display: block;
        padding-top: 40px;
      }
    }
    .mpp__product-grid {
      width: 100%;
      float: left;
    }
    .button--order-details {
      float: right;
      text-align: center;
      padding: 9px 19px 8px 19px;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      min-width: 116px;
      max-width: 100%;
      font-size: 15px;
      line-height: 16px;
      background: #fbf8f6;
      color: #392720;
      border: 1px solid #392720;
      background: #392720;
      color: #fbf8f6;
      -webkit-appearance: none;
      border-radius: 0;
    }
    a.button--order-details:hover {
      color: white;
    }
  }
  .order-details-page__content {
    margin-bottom: 50px;
    .order-details--delivery-options,
    .order-details--shipping-address,
    .gift-options,
    .gift-options__info,
    .order-details--payment-info {
      padding-bottom: 15px;
      line-height: 19px;
      padding-top: 15px;
      h4 {
        border-bottom: 1px solid #cdcbc9;
        color: #969696;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .cart-item-table {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    th {
      text-align: center;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.15em;
      color: #a4a4ab;
      padding: 0 0 10px 0;
      vertical-align: bottom;
    }
    tbody {
      border-top: 1px solid #ccc;
    }
    tr {
      border-collapse: collapse;
      border-spacing: 0;
      border-bottom: 0;
      margin: 0;
      padding: 0;
      td {
        padding-right: 10px;
        width: auto;
        letter-spacing: 0.1em;
        padding: 15px;
        vertical-align: top;
      }
    }
    .cart-item {
      overflow: visible;
      width: 100%;
      position: relative;
      display: table-row;
    }
    .cart-item__product-name {
      font-weight: bold;
    }
  }
  .order_summary_spacer {
    border-top: none;
    margin-top: 8px;
    padding-bottom: 8px;
  }
  .order_summary {
    text-align: left;
    float: right;
    padding-bottom: 10px;
    border-bottom: none;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-weight: normal;
  }
  .sidebar-page {
    border: none;
  }
}

.past-purchases {
  &-page__content {
    float: left;
    width: 73%;
    .past-purchases {
      float: left;
    }
    .grid--mpp__item {
      width: 30%;
      float: left;
      padding: 0 17px;
      margin-bottom: 42px;
    }
    .product-item {
      &__details {
        min-height: 184px;
        position: relative;
      }
      &__add-to-cart {
        position: absolute;
        bottom: 0;
        a.button {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}

.order-details-page {
  .order-summary__item {
    margin-bottom: 10px;
  }
  .headline--page {
    border-bottom: solid 1px #cccccc;
    text-align: center;
    margin-bottom: 20px;
    font-size: 41px;
  }
  .margin_top_bottom {
    margin-bottom: 15px;
    margin-top: 10px;
    a.form-submit {
      text-align: center;
      padding: 9px 19px 8px 19px;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      min-width: 116px;
      max-width: 100%;
      font-size: 15px;
      line-height: 16px;
      background: #fbf8f6;
      color: #392720;
      border: 1px solid #392720;
      background: #392720;
      color: #fbf8f6;
      -webkit-appearance: none;
      border-radius: 0;
    }
  }
  .order-summary {
    .order_cancel {
      text-decoration: underline;
    }
  }
  #return-info-wrapper {
    .order_return_instructions {
      a {
        text-decoration: underline;
      }
    }
  }
}

#order_return {
  #product-info-wrapper {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
    .cart-items {
      &__item {
        width: 100%;
        .cart-items {
          &__item--thumb {
            width: 28%;
            float: left;
          }
          &__item--desc {
            width: 20%;
            float: left;
          }
          &__price {
            width: 20%;
            float: left;
          }
          &__qty {
            width: 18%;
            float: left;
          }
          &__total {
            width: 14%;
            float: left;
          }
        }
      }
    }
    #product-container {
      th {
        width: 20%;
      }
    }
  }
  #return-info-wrapper {
    #return-details {
      .form-item {
        margin-bottom: 20px;
      }
    }
    #return-address {
      .edit-address {
        text-decoration: underline;
      }
    }
  }
  .headline--section {
    font-size: 30px;
    border-bottom: 1px solid #cccccc;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .edit-address {
    font-size: 13px;
  }
}

#return-address {
  #address_form_container {
    .required_mark {
      display: block;
    }
    .form-item-postal,
    .phone {
      margin-bottom: 20px;
    }
    .phone {
      fieldset,
      input[type='text'] {
        line-height: 0px;
      }
      .select-box {
        &__label {
          height: 35px;
        }
      }
    }
    div.form-item {
      &:nth-child(3),
      &:nth-child(4) {
        input {
          width: 42%;
        }
      }
    }
  }
}

#order_return_confirm {
  .order-details-page {
    .form-submit {
      text-decoration: underline;
    }
  }
}

#account-page__welcome,
.account-payment,
.account-address {
  a {
    text-decoration: underline;
  }
}

#signin {
  .signin-block {
    .return-user {
      &__item {
        a {
          text-decoration: underline;
        }
      }
      &__fieldset {
        margin-bottom: 10px;
      }
    }
  }
}

.signin-block {
  margin-top: 30px;
  &__close {
    .icon--close {
      width: 20px;
      height: 20px;
    }
  }
  .signin-block__authed__nav {
    width: 325px;
  }
}

.account-utilities__section {
  padding-top: 18px;
}

.tout--landscape {
  width: auto;
  margin-#{$ldirection}: 9%;
}
