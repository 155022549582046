.change-password {
  #colorbox {
    transform: translate(-50%);
  }
  .password-field {
    &__info-reset {
      width: 100%;
      @include breakpoint($large-up) {
        width: 85%;
      }
      &::before {
        #{$rdirection}: 100%;
      }
    }
    &__rules {
      column-count: 1;
      font-size: 1em;
      @include breakpoint($large-up) {
        font-size: 0.7em;
        padding: 10px;
      }
      padding: 0;
    }
  }
  .profile__reset-password {
    .profile-info {
      &__fieldset {
        max-width: 87%;
        @include breakpoint($large-up) {
          min-width: 100%;
        }
      }
    }
    .password-field {
      &__rules {
        font-size: 0.4em;
        @include breakpoint($large-up) {
          font-size: 0.6em;
        }
        padding: 0;
      }
    }
  }
  #my-appts-registration-form {
    .registration-password-container {
      .password-field {
        &__info {
          @include breakpoint($large-up) {
            #{$ldirection}: 55%;
            width: 25%;
          }
        }
      }
    }
  }
}

.signin-block__forms {
  .change-password {
    .password-field {
      &__info {
        width: 32%;
      }
    }
  }
}
