$color-dark-grey-3: #a9a9a9;

.appt-book-sections-container {
  #booking-step3 {
    .book-appt-container {
      .signin-block__field-wrapper {
        &.registration-first-name {
          width: 100%;
        }
        &.no-last-name {
          display: none;
        }
      }
      .appt_booking_helpers {
        float: right;
        .appt_kr_phone_code,
        .appt_mobile_part1,
        .appt_mobile_part2 {
          float: left;
          width: 32%;
          @include breakpoint($medium-down) {
            &[type='tel'] {
              width: 32%;
            }
          }
        }
        .appt_kr_phone_code {
          height: 40px;
          .select-box__label {
            height: 40px;
            line-height: 40px;
            &::after {
              line-height: 40px;
            }
          }
        }
        .appt_mobile_phone_seperator {
          float: left;
          line-height: 2.5;
          width: 2%;
          text-align: center;
        }
      }
      .login-password {
        &.guest {
          display: none;
        }
      }
      .accept-terms-head,
      .accept-thirdparty-head,
      .accept-privacy-head,
      .accept-sms-notification-head {
        font-weight: bold;
      }
      .legal_statement {
        border: 1px solid $color-dark-grey-3;
        height: 87px;
        line-height: 18px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 5px;
      }
      .radio_group {
        margin: 10px 0;
        &.email_block,
        &.sms_block,
        &.postal_block {
          float: left;
          width: 33%;
        }
      }
      .radio {
        display: inline-table;
        margin-bottom: 10px;
        height: auto;
        input[type='radio'] {
          width: 13px;
          margin: 0;
          display: inline-block;
          height: auto;
        }
        .error {
          color: $color-light-pink;
        }
      }
    }
  }
}

.appt-book {
  &.services {
    .appt-book-section {
      .appt-book-section-content {
        .service-content__details--title {
          margin-top: 2px;
        }
      }
    }
  }
}

.appt-book-page-header {
  .appt-book-page-header__banner {
    .appt-book-page-header__content {
      font-size: 20px;
      h2 {
        font-size: 40px;
      }
    }
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
